import React, { useEffect, useState, useRef} from 'react'
import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, Offcanvas, OffcanvasBody, Row, TabContent, TabPane, UncontrolledDropdown, NavLink } from 'reactstrap'
import { addCompanyApi, companyDeleteApi, companyEditApi, companyViewApi, getAllCompaniesApi, getAllSubscriptionPlansApi, stripeRefund} from './api'
import PaginationComponent from '../../Components/Common/Pagination'
import { FormControl, InputLabel, MenuItem, Select, TextField, CircularProgress} from '@mui/material'
import SimpleBar from "simplebar-react"
// import { NavLink } from 'react-router-dom'
import classnames from "classnames"
import Flatpickr from 'react-flatpickr'
import { convertDateTimeString } from '../../helpers/date-time'
import Swal from 'sweetalert2'
import * as Yup from "yup"
import { useFormik } from 'formik'
import FilterSidebar from './Modals/Filter'
import { dateFormat, dateFormatFlatPicker, planName, termName, EnterpriseCompanyType } from '../../helpers/constants'
import moment from "moment"
import { getColumnSize, updateColumnSize, activityApi } from '../../api/commonApi'
import ResizableHeaderCell from '../../Components/Common/ColumnResize'
import Activity from '../../Components/Common/Activity'
import GenerateLicenseKey from '../../Components/Common/GenerateLicenseKey'

const Subscriptions = () => {
  document.title ="Subscription Management | Vardian"
  const [sortBy, setSortBy] = useState({})
  const [meta, setMeta] = useState({})
  const [plans, setPlans] = useState([])
  const [fetch, setFetch] = useState(false)
  const [openFilterSidebar, setOpenFilterSidebar] = useState(false)
  const [selectedPlanName, setSelectedPlanName] = useState()
  const [editSelectedPlanName, setEditSelectedPlanName] = useState()
  const [addSubscription,setAddSubscription] = useState(false)
  const [detail_list, setdetail_list] = useState(false)
  const [cardHeaderTab, setcardHeaderTab] = useState("1")
  const [companies,setCompanies] = useState([])
  const [viewCompany,setViewCompany] = useState()
  const [editSubscriptionModal,setEditSubscriptionModal] = useState(false)
  const [editCompanyId,setEditCompanyId] = useState()
  const [isLoading,setIsLoading] = useState(false)
  const [columnWidths,setColumnWidths]=useState(null)
  const [sizeRefetch,setSizeRefetch]=useState(false)
  const [limit, setLimit] = useState(10)
  const [filteredPlans, setFilteredPlans] = useState()
  const [activity, setActivity] = useState()
  const [activityMeta,setActivityMeta] = useState()

  const addInputDateRef = useRef()
  const editInputDateRef = useRef()


  const date = new Date()
  date.setHours(0, 0, 0, 0)
  // const isoDate= date.toISOString()

  const columns = [
    { name: 'Company Name', key: 'company_name' }
  ]

  const column2 = [
    { name: 'Status', key: 'is_active' },
  ]

  const toggleFilterSidebar = ()=>{
    setOpenFilterSidebar(!openFilterSidebar)
  }

  const handleSort = (column) => {
    if (sortBy.column === column) {
      setSortBy({
        column,
        order: sortBy.order === 'desc' ? 'asc' : 'desc',
      })
    } else {
      setSortBy({ column, order: 'desc' })
    }
  }

  const getData = ()=>{
    setFetch(!fetch)
  }

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const noSpecialCharRegex = /^[a-zA-Z0-9\s]*$/

  const addMonthToDate = (startDate) => {
    const date = new Date(startDate)
    // Calculate the new month
    let newMonth = date.getMonth() + 1
    let newYear = date.getFullYear()

    // Handle year change if needed
    if (newMonth > 11) {
      newMonth = 0
      newYear += 1
    }

    // Calculate the end date
    const endDate = new Date(newYear, newMonth, date.getDate())

    // Handle end-of-month case
    if (date.getDate() > 28) {
      const lastDayOfMonth = new Date(newYear, newMonth + 1, 0).getDate()
      if (date.getDate() > lastDayOfMonth) {
        endDate.setDate(lastDayOfMonth)
      }
    }

    return endDate
  }

  const addYearToDate = (startDate) => {
    // Create a new Date object based on the start date
    const date = new Date(startDate)

    // Calculate the new year
    let newYear = date.getFullYear() + 1

    // Calculate the end date
    const endDate = new Date(newYear, date.getMonth(), date.getDate())

    // Handle end-of-month case
    if (date.getDate() > 28) {
      // Get the last day of the new month
      const lastDayOfMonth = new Date(newYear, date.getMonth() + 1, 0).getDate()
      if (date.getDate() > lastDayOfMonth) {
        endDate.setDate(lastDayOfMonth)
      }
    }

    return endDate
  }

  const addDaysToDate = (startDate, days) => {
    const date = new Date(startDate)
    date.setDate(date.getDate() + days)
    return date
  }

  const convertToUTCISO = (dateString) => {
    // Parse the input date string
    const date = new Date(dateString)

    // Convert the date to UTC ISO 8601 format
    const utcDate = date.toISOString()

    return utcDate
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      company_name : '',
      subscription_plan : '',
      term: '',
      user: '',
      monthly_cost: '',
      yearly_cost: '',
      start_date: '',
      end_date: '',
      grace_period: 0,
      contact_name: '',
      contact_email: '',
      contact_phone: '',
      contact_address: '',
      trial_period: 14,
      subdomain_name:'',
      enterprise_type:''
    },
    validationSchema: Yup.object({
      company_name: Yup.string().trim().required("Please enter company name").matches(noSpecialCharRegex, "Company name must not include any special characters"),
      subscription_plan: Yup.number().required("Please select the plan type"),
      trial_period: Yup.number().min(0, "Trial period cannot be negative").max(365,"Trial period should be less than 365 days"),
      subdomain_name:Yup.string(),
      term: Yup.string().trim().required("Please enter term"),
      user: Yup.number().min(1,"User number should be at least 1").required("Please enter user number").max(10000,"User name should be less than 10000"),
      monthly_cost: Yup.number().min(1,"Monthly price/user number should be at least 1").required("Please enter monthly price per user").max(10000,"Monthly cost should be less than 10000"),
      yearly_cost: Yup.number().min(1,"Yearly price/user number should be at least 1").required("Please enter yearly price per user").max(10000,"Yearly cost should be less than 10000"),
      grace_period: Yup.number().min(0, "Trial period cannot be negative").max(60,"Grace period should be less than 100 days"),
      start_date: Yup.date().required("Please enter subscription start date"),
      enterprise_type: Yup.string().when('subscription_plan', {
        is: (value) => {
          const exists = plans.find((plan) => plan.id === value)?.name === planName.ENTERPRISE
          return exists
        },
        then: Yup.string().required('Please select enterprise type.'),
        otherwise: Yup.string().notRequired(),
      }),
      contact_name: Yup.string().trim().required("Contact name is required").matches(noSpecialCharRegex, "Company name must not include any special characters"),
      contact_email: Yup.string().email("Please enter valid email").trim().required("Please enter email"),
      contact_phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').trim().required("Phone number is required"),
      contact_address: Yup.string().trim().required('Please enter address')
    }),
    onSubmit: async (values) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure you want to add company?',
        showConfirmButton: true,
        reverseButtons: true,
        confirmButtonColor: '#3085d6',
        showCancelButton: true
      }).then(async (isConfirm)=>{
        if(isConfirm.isConfirmed){
          try {
            const payload = {
              company_name: values.company_name.trim(),
              ...(selectedPlanName === planName.ENTERPRISE && { subdomain_name: values.subdomain_name }),
              "subscriptions": [
                {
                  "plan_id": values.subscription_plan,
                  ...(selectedPlanName === planName.TRIAL && { trial_period: values.trial_period }),
                  "end_date": (moment(moment(values.end_date).toDate()).endOf('day')['_d']).toISOString(),
                  "next_renewal_date": (moment(moment(values.next_renewal_date).toDate()).endOf('day')['_d']).toISOString(),
                  "term": values.term.trim(),
                  "user_limit": values.user,
                  "monthly_cost": values.monthly_cost,
                  "yearly_cost": values.yearly_cost,
                  "start_date": (moment(moment(values.start_date).toDate()).endOf('day')['_d']).toISOString(),
                  "grace_period": values.grace_period || 0,
                  ...(plans.find((plan) => plan.id === values.subscription_plan)?.name === planName.ENTERPRISE && {enterprise_type: values.enterprise_type })
                }
              ],
              "contacts": [
                {
                  "name": values.contact_name,
                  "email": values.contact_email,
                  "phone": values.contact_phone,
                  "address": values.contact_address
                }
              ]
            }
            const response = await addCompanyApi(payload)
            if(response.error){
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: response.payload.message,
              })
            }else{
              Swal.fire({
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              })
              validation.resetForm()
              tog_add_subscription()
              setSelectedPlanName()
              getData()
            }
          } catch (error) {
            console.error("error", error)
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message,
            })
          }
        }
      })
    }
  })

  const editValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      company_name : '',
      subscription_plan : '',
      next_renewal_date: '',
      term: '',
      user: '',
      monthly_cost: '',
      yearly_cost: '',
      end_date: '',
      grace_period: '',
      start_date: '',
      trial_period: 14,
      subdomain_name:'',
      enterprise_type:''
    },
    validationSchema: Yup.object({
      is_active: Yup.boolean().required('Please select company status'),
      next_renewal_date: Yup.date().required("Please enter next renewal date"),
      company_name: Yup.string().trim().required("Please enter company name").matches(noSpecialCharRegex, "Company name must not include any special characters"),
      subscription_plan: Yup.number().required("Please select a plan"),
      trial_period: Yup.number().min(0, "Trial period cannot be negative number"),
      subdomain_name:Yup.string(),
      term: Yup.string().trim().required("Please select a term"),
      user: Yup.number().min(1,"User limit should be at-least 1").required("Please enter user limit"),
      monthly_cost: Yup.number().min(1,"price/user number should be at least 1").required("Please enter monthly price per user"),
      yearly_cost: Yup.number().min(1,"Price per user number should be at-least 1$").required("Please enter yearly price per user"),
      grace_period: Yup.number().min(0, "Trial period cannot be negative"),
      start_date: Yup.date().required("Please select subscription start date"),
      end_date: Yup.date().required("Please select subscription start date"),
      enterprise_type: Yup.string().when('subscription_plan', {
        is: (value) => {
          const exists = plans.find((plan) => plan.id === value)?.name === planName.ENTERPRISE
          return exists
        },
        then: Yup.string().required('Please select enterprise type.'),
        otherwise: Yup.string().notRequired(),
      }),
    }),
    onSubmit: async (values) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure you want to edit company?',
        showConfirmButton: true,
        reverseButtons: true,
        confirmButtonColor: '#3085d6',
        showCancelButton: true
      }).then(async (isConfirm)=>{
        if(isConfirm.isConfirmed){
          try {
            const payload = {
              company_name: values.company_name.trim(),
              "is_active": values.is_active,
              ...(editSelectedPlanName === planName.ENTERPRISE && { subdomain_name: values.subdomain_name }),
              "subscriptions": [
                {
                  "plan_id": values.subscription_plan,
                  ...(editSelectedPlanName === planName.TRIAL && { trial_period: values.trial_period }),
                  "end_date":  (moment(moment(values.end_date).toDate()).endOf('day')['_d']).toISOString(),
                  "next_renewal_date":  (moment(moment(values.next_renewal_date).toDate()).endOf('day')['_d']).toISOString(),
                  "grace_period": values.grace_period,
                  "term": values.term.trim(),
                  "user_limit": values.user,
                  "monthly_cost": values.monthly_cost,
                  "yearly_cost": values.yearly_cost,
                  "start_date":  (moment(moment(values.start_date).toDate()).endOf('day')['_d']).toISOString(),
                  ...(editSelectedPlanName === planName.ENTERPRISE && {enterprise_type: values.enterprise_type})
                }
              ]
            }
            const response = await companyEditApi(editCompanyId,payload)
            if(response.error){
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: response.payload.message,
              })
            }else{
              Swal.fire({
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              })
              validation.resetForm()
              tog_edit_subscription_modal()
              setEditSelectedPlanName()
              getData()
            }
          } catch (error) {
            console.error("error", error)
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message,
            })
          }
        }
      })
    }
  })

  const tog_add_subscription = () => {
    setAddSubscription(!addSubscription)
    if (addSubscription) {
      validation.resetForm()
      setSelectedPlanName('')
    }
  }

  const tog_edit_subscription_modal = () => {
    setEditSubscriptionModal(!editSubscriptionModal)
  }

  const tog_detail_list = () => {
    setdetail_list(!detail_list)
  }

  const cardHeaderToggle = (tab) => {
    if (cardHeaderTab !== tab) {
      setcardHeaderTab(tab)
    }
  }

  const fetchData = async() => {
    try {
      setIsLoading(true)
      const response = await getAllCompaniesApi({...sortBy,limit})
      setCompanies(response.data.data.data)
      setMeta(response.data.data.meta)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchPlans = async() => {
    const response = await getAllSubscriptionPlansApi()
    const filteredPlans = response.data.subscription_plans.filter(item => item.name !== "Professional")
    setPlans(response.data.subscription_plans)
    setFilteredPlans(filteredPlans)
  }

  useEffect(()=>{
    fetchPlans()
  },[])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sortBy,fetch])

  useEffect(() => {
    if (validation.values.start_date && validation.values.subscription_plan) {
      calculateNextRenewalDate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation.values.start_date, validation.values.term, validation.values.trial_period, validation.values.grace_period, validation.values.subscription_plan])

  useEffect(() => {
    if (editValidation.values.start_date && editValidation.values.subscription_plan) {
      calculateNextEditRenewalDate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editValidation.values.start_date, editValidation.values.term, editValidation.values.trial_period, editValidation.values.grace_period, editValidation.values.subscription_plan])


  const calculateTimeRemaining = (start_date, end_date) => {
    if (start_date === null || end_date === null){
      return "N/A"
    }
    const now = moment()
    const endDate = moment(end_date)
    const startDate = moment(start_date)

    if (endDate < now) {
      return (<span className="badge cstm_badge badge-soft-danger">Expired</span>)
    }

    if (startDate > now) {
      return (<span className="badge cstm_badge badge-soft-primary">Not Started</span>)
    }

    if (endDate < startDate) {
      return "N/A"
    }

    const duration = moment.duration(endDate.diff(now))

    const numOfDays = Math.ceil(duration.asDays())

    return (<span className="badge cstm_badge badge-soft-success">{`${numOfDays} Day${numOfDays !== 1 ? 's' : ''}`}</span>)

  }

  const [viewSubscriptionId,setViewSubscriptionId] = useState()

  // const [detail_list, setdetail_list] = useState(false)
  const handleView = async (id) => {
    if (id) {
      await Promise.all([getSubscription(id), subscriptionActivity(id, 1)])
    }
    cardHeaderToggle("1")
    tog_detail_list(true)
  }

  const getSubscription = async(id) => {
    try {
      setViewSubscriptionId(id)
      const response = await companyViewApi(id)
      setViewCompany(response.data.company)
    } catch(error) {
      console.error(error)
    }
  }

  const handleEdit = async(id)=>{
    try{
      setEditCompanyId(id)
      const response = await companyViewApi(id)
      formReset(response.data.company)
      setEditSubscriptionModal(true)
    }catch(error){
      console.error("error fetching data ",error)
    }
  }

  const handleCancellation = async(id) => {
    const result = await Swal.fire({
      title: 'Are you sure you want to cancel the subscription ?',
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })
    if (result.isConfirmed) {
      try {
        const response = await stripeRefund(id)
        Swal.fire({
          icon: 'success',
          title: `Refund Initiated for: $ ${response.data.totalRefund}`,
          showConfirmButton: true,
          confirmButtonText: 'Confirm'
        })
        getData()
        tog_detail_list()
      } catch (err) {
        Swal.fire({
          icon: 'error',
          title: err.response?.data?.message || 'An error occurred',
          showConfirmButton: false,
          cancelButtonText: 'Cancel',
          timer: 3000,
        })
      }
    }
  }

  const handleDelete = async(id) => {
    const dialog = await Swal.fire({
      title: 'Are you sure want to delete this company?',
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    })
    if (dialog.isConfirmed) {
      try {
        const response = await companyDeleteApi(id)
        if (response.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.payload.message,
          })
        } else {
          Swal.fire({
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
          getData()
        }
      } catch (e) {
        console.error(e)
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: e.response.data.message ?? 'Some error occure',
        })
      }
    }
  }

  const formReset = (data)=>{
    editValidation.setFieldValue("company_name", data.company_name)
    editValidation.setFieldValue("subscription_plan", data.subscriptions[0].plan.id)
    editValidation.setFieldValue('is_active',data.is_active)
    data.subscriptions[0].trial_period && editValidation.setFieldValue("trial_period", data.subscriptions[0].trial_period)
    editValidation.setFieldValue('start_date', data.subscriptions[0].start_date)
    editValidation.setFieldValue('next_renewal_date', data.subscriptions[0].next_renewal_date)
    editValidation.setFieldValue('end_date', data.subscriptions[0].end_date)
    editValidation.setFieldValue('grace_period', data.subscriptions[0].grace_period)
    editValidation.setFieldValue('term', data.subscriptions[0].term)
    editValidation.setFieldValue('user', data.subscriptions[0].user_limit)
    editValidation.setFieldValue('monthly_cost', data.subscriptions[0].monthly_cost)
    editValidation.setFieldValue('yearly_cost', data.subscriptions[0].yearly_cost)
    editValidation.setFieldValue('enterprise_type', data.subscriptions[0].enterprise_type ?? '')
    data.subdomain_name && editValidation.setFieldValue('subdomain_name', data.subdomain_name)

    setEditSelectedPlanName(data.subscriptions[0].plan.name)
  }

  const calculateNextRenewalDate = async() => {
    const startDate = validation.values.start_date
    const term = validation.values.term
    const grace_period = validation.values.grace_period || 0
    const trial_period = validation.values.trial_period || 0

    if (selectedPlanName === "Trial") {
      const endDate = await addDaysToDate(startDate, Number(trial_period - 1))
      const nxtRenewal = await addDaysToDate(endDate,1)
      validation.setFieldValue('next_renewal_date', nxtRenewal)
      validation.setFieldValue('end_date', endDate)
    } if (selectedPlanName === "Enterprise") {
      if (term  === termName.YEAR) {
        const endDate = await addYearToDate(startDate)
        const nxtRenewalDate =  await addDaysToDate(endDate, (Number(grace_period)+1))

        validation.setFieldValue('end_date',endDate)
        validation.setFieldValue('next_renewal_date', nxtRenewalDate)

      } else if (term === termName.MONTH) {
        const endDate =  await addMonthToDate(startDate)
        const nxtRenewalDate =  await addDaysToDate(endDate, (Number(grace_period)+1))

        validation.setFieldValue('end_date', endDate)
        validation.setFieldValue('next_renewal_date', nxtRenewalDate)

      }
    }
  }

  const calculateNextEditRenewalDate = async() => {

    const startDate = editValidation.values.start_date
    const term = editValidation.values.term
    const grace_period = editValidation.values.grace_period || 0
    const trial_period = editValidation.values.trial_period || 0

    if (editSelectedPlanName === "Trial") {
      const endDate = await addDaysToDate(startDate, Number(trial_period - 1))
      const nxtRenewal = await addDaysToDate(endDate,1)
      editValidation.setFieldValue('next_renewal_date', nxtRenewal)
      editValidation.setFieldValue('end_date', endDate)
    } if (editSelectedPlanName === "Enterprise") {
      if (term === termName.YEAR) {

        const endDate = await addYearToDate(startDate)
        const nxtRenewalDate =  await addDaysToDate(endDate, (Number(grace_period)+1))

        editValidation.setFieldValue('end_date',endDate)
        editValidation.setFieldValue('next_renewal_date',nxtRenewalDate)

      } else if (term === termName.MONTH) {
        const endDate =  await addMonthToDate(startDate)
        const nxtRenewalDate =  await addDaysToDate(endDate, (Number(grace_period) +1))

        editValidation.setFieldValue('end_date',endDate)
        editValidation.setFieldValue('next_renewal_date',nxtRenewalDate)

      }
    }
  }

  const subscriptionActivity = async(id, page) =>
  {
    try {
      const response = await activityApi(page ,id)
      setActivity(response.data.data.data)
      setActivityMeta(response.data.data.meta)
    } catch (error) {
      console.error('Error fetching data: ', error)
    }
  }

  const fetchColSize = async () => {
    try {
      const response=await getColumnSize('subscription_management')
      setColumnWidths(response.data.columns)
    } catch(error) {
      console.error("Some error occoured :"+error)
    }
  }

  useEffect( () => {
    fetchColSize()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ sizeRefetch ])

  const handleResize = async(columnKey, newWidth) => {
    const columnKeyUpdated = columnKey.toLowerCase().replace(/\s/g, '_')
    try{
      const payload = {
        "column_width":newWidth,
        "table_name": "subscription_management",
        "column_name": columnKeyUpdated
      }
      await updateColumnSize(payload)
      setSizeRefetch(!sizeRefetch)
    } catch(error) {
      console.error("error occurred : ",error)
    }
  }

  const [openLicenseKeyModal, setOpenLicenseKeyModal] = useState(false)
  const [selectedCompanyId, setSelectedCompanyId] = useState(null)
  const handleOpenGenerateLicenseKey = async (id) => {
    setSelectedCompanyId(id)
    setOpenLicenseKeyModal(!openLicenseKeyModal)
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <div className='main_page_heading_holder d-flex justify-content-between align-items-center pb-3'>
            <h4 className="bold_heading mb-0">Subscription Management</h4>
            <div>
              <Button onClick={toggleFilterSidebar} color="dark" outline className='d-inline-flex me-2'>
                <i className="ri-equalizer-line"></i>
                <span className='ps-1'>Filter</span>
              </Button>
              <Button color="primary" className='d-inline-flex main_primary_btn' onClick={() => setAddSubscription(true)}>
                <i className="ri-add-line"></i>
                <span className='ps-1'>Add New Subscription</span>
              </Button>
            </div>
          </div>
          <Card>
            <CardBody>
              <div id="customerList">
                <div className="table-responsive table-card">
                  {!isLoading ? (
                    companies.length > 0 ? (
                      <table className="table align-middle table-nowrap resizeable_table" id="customerTable">
                        <thead className="table-dark">
                          <tr>
                            {columns?.map((column,index) => (
                              <ResizableHeaderCell
                                key={index}
                                width={columnWidths && (columnWidths.find(item => item.column_name === column.name.toLowerCase().replace(/\s/g, '_')))?.column_width}
                                onResizeProp={(newWidth) => {
                                  handleResize(column.name, newWidth)}}>
                                <div
                                  key={column.key}
                                  onClick={() => handleSort(column.key)}
                                  className={`sortable-header ${sortBy.column !== column.key ? 'not-sorted' : ''} heading_holder bg-dark`}
                                >
                                  {column.name}
                                  {sortBy.column === column.key && (
                                    <span className="d-inline-flex align-items-center" style={{ fontSize: '12px', marginLeft: '6px', color: 'blue', cursor: 'pointer' }}>
                                      {sortBy.order === 'asc' ? <i className="ri-arrow-up-s-fill"></i> : <i className="ri-arrow-down-s-fill"></i>}
                                    </span>
                                  )}
                                  {sortBy.column !== column.key && (
                                    <span className="d-inline-flex align-items-center" style={{ fontSize: '12px', marginLeft: '6px', color: '#ccc', cursor: 'pointer' }}>
                                      <i className="ri-arrow-up-s-fill"></i>
                                      <i className="ri-arrow-down-s-fill"></i>
                                    </span>
                                  )}
                                </div>
                              </ResizableHeaderCell>
                            ))}
                            <ResizableHeaderCell
                              width={columnWidths && (columnWidths.find(item => item.column_name === 'next_renewal_date'))?.column_width}
                              onResizeProp={(newWidth) => {
                                handleResize('next_renewal_date', newWidth)}}>
                              <div className='heading_holder bg-dark'>Next Renewal Date</div>
                            </ResizableHeaderCell>
                            <ResizableHeaderCell
                              width={columnWidths && (columnWidths.find(item => item.column_name === 'user_limit'))?.column_width}
                              onResizeProp={(newWidth) => {
                                handleResize('user_limit', newWidth)}}>
                              <div className='heading_holder bg-dark'>User Limit</div>
                            </ResizableHeaderCell>
                            <ResizableHeaderCell
                              width={columnWidths && (columnWidths.find(item => item.column_name === 'time_remaining'))?.column_width}
                              onResizeProp={(newWidth) => {
                                handleResize('time_remaining', newWidth)}}>
                              <div className='heading_holder bg-dark'>Time Remaining</div>
                            </ResizableHeaderCell>
                            <ResizableHeaderCell
                              width={columnWidths && (columnWidths.find(item => item.column_name === 'subscription_plan'))?.column_width}
                              onResizeProp={(newWidth) => {
                                handleResize('subscription_plan', newWidth)}}>
                              <div className='heading_holder bg-dark'>Subscription Plan</div>
                            </ResizableHeaderCell>
                            <ResizableHeaderCell
                              width={columnWidths && (columnWidths.find(item => item.column_name === 'monthly_cost'))?.column_width}
                              onResizeProp={(newWidth) => {
                                handleResize('monthly_cost', newWidth)}}>
                              <div className='heading_holder bg-dark' >Monthly Cost</div>
                            </ResizableHeaderCell>
                            <ResizableHeaderCell
                              width={columnWidths && (columnWidths.find(item => item.column_name === 'yearly_cost'))?.column_width}
                              onResizeProp={(newWidth) => {
                                handleResize('yearly_cost', newWidth)}}>
                              <div className='heading_holder bg-dark' >Yearly Cost</div>
                            </ResizableHeaderCell>
                            <ResizableHeaderCell
                              width={columnWidths && (columnWidths.find(item => item.column_name === 'term'))?.column_width}
                              onResizeProp={(newWidth) => {
                                handleResize('term', newWidth)}}>
                              <div className='heading_holder bg-dark'>Term</div>
                            </ResizableHeaderCell>
                            {column2?.map((column,index) => (
                              <ResizableHeaderCell
                                key={index}
                                width={columnWidths && (columnWidths.find(item => item.column_name === column.name.toLowerCase().replace(/\s/g, '_')))?.column_width}
                                onResizeProp={(newWidth) => {
                                  handleResize(column.name, newWidth)}}>
                                <div
                                  key={column.key}
                                  onClick={() => handleSort(column.key)}
                                  className={`sortable-header ${sortBy.column !== column.key ? 'not-sorted' : ''} heading_holder bg-dark`}
                                >
                                  {column.name}
                                  {sortBy.column === column.key && (
                                    <span className="d-inline-flex align-items-center" style={{ fontSize: '12px', marginLeft: '6px', color: 'blue', cursor: 'pointer' }}>
                                      {sortBy.order === 'asc' ? <i className="ri-arrow-up-s-fill"></i> : <i className="ri-arrow-down-s-fill"></i>}
                                    </span>
                                  )}
                                  {sortBy.column !== column.key && (
                                    <span className="d-inline-flex align-items-center" style={{ fontSize: '12px', marginLeft: '6px', color: '#ccc', cursor: 'pointer' }}>
                                      <i className="ri-arrow-up-s-fill"></i>
                                      <i className="ri-arrow-down-s-fill"></i>
                                    </span>
                                  )}
                                </div>
                              </ResizableHeaderCell>
                            ))}
                            <th className='heading_holder bg-dark' style={{ width: '50px' }}>Actions</th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {companies.map((company,index) => (
                            <tr key={index} onClick={()=> handleView(company.id)}>
                              <td>
                                <div className='desc_tabledata_holder thin_scrollbar'>
                                  <span onClick={(e) => e.stopPropagation()}>{company?.company_name}</span>
                                </div>
                              </td>
                              <td>
                                <div className='desc_tabledata_holder thin_scrollbar'>
                                  <span onClick={(e) => e.stopPropagation()}>{company.subscriptions[0]?.next_renewal_date ? company.subscriptions[0]?.next_renewal_date && convertDateTimeString(company.subscriptions[0]?.next_renewal_date,dateFormat) : 'N/A'}</span>
                                </div>
                              </td>
                              <td>
                                <div className='desc_tabledata_holder thin_scrollbar'>
                                  <span onClick={(e) => e.stopPropagation()}>{company?.subscriptions[0]?.user_limit ?? 0}</span>
                                </div>
                              </td>
                              <td>
                                <div className='desc_tabledata_holder thin_scrollbar'>
                                  <span onClick={(e) => e.stopPropagation()}>{company.subscriptions[0]?.next_renewal_date ? company.subscriptions[0]?.start_date && company.subscriptions[0]?.next_renewal_date && calculateTimeRemaining(company.subscriptions[0]?.start_date,company.subscriptions[0]?.next_renewal_date) : 'N/A'}</span>
                                </div>
                              </td>
                              <td>
                                <div className='desc_tabledata_holder thin_scrollbar'>
                                  <span onClick={(e) => e.stopPropagation()}>{`${company.subscriptions[0]?.plan.name ? (company.subscriptions[0]?.plan.name === planName.ENTERPRISE ? (company.subscriptions[0]?.plan.name + ' (' + company.subscriptions[0]?.enterprise_type+ ')') : company.subscriptions[0]?.plan.name) : 'N/A'}`}</span>
                                </div>
                              </td>
                              <td>
                                <div className='desc_tabledata_holder thin_scrollbar'>
                                  <span onClick={(e) => e.stopPropagation()}>{company.subscriptions[0]?.monthly_cost ?? 0}</span>
                                </div>
                              </td>
                              <td>
                                <div className='desc_tabledata_holder thin_scrollbar'>
                                  <span>{company.subscriptions[0]?.yearly_cost ?? 0}</span>
                                </div>
                              </td>
                              <td>
                                <div className='desc_tabledata_holder thin_scrollbar'>
                                  <span onClick={(e) => e.stopPropagation()}>{company.subscriptions[0]?.term ?? 'N/A'}</span>
                                </div>
                              </td>
                              <td className="status desc_tabledata_holder thin_scrollbar">
                                <span className={`badge cstm_badge badge-soft-${company.is_active ? 'success' : 'danger'}`} onClick={(e) => e.stopPropagation()}>{company.is_active? 'Active' : "Inactive"}</span>
                              </td>
                              <td onClick={(e)=>e.stopPropagation()}>
                                <UncontrolledDropdown className="dropdown d-inline-block position-static">
                                  <DropdownToggle className="btn btn-sm position-static" tag="button">
                                    <i className="ri-more-2-fill align-middle"></i>
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => handleView(company.id)}><i className="ri-eye-fill align-bottom me-2 text-muted"></i>View</DropdownItem>
                                    <DropdownItem disabled={company.is_active} className='edit-item-btn' onClick={() => handleEdit(company.id)}><i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit</DropdownItem>
                                    <DropdownItem disabled={!(company.subscriptions[0]?.plan.name === planName.ENTERPRISE && [EnterpriseCompanyType.ON_PREM, EnterpriseCompanyType.ON_CLOUD_COMPANY].includes(company.subscriptions[0]?.enterprise_type))} onClick={() => handleOpenGenerateLicenseKey(company.id)}>
                                      <i className="ri-key-fill align-bottom me-2 text-muted"></i>
                                      Generate License Key
                                    </DropdownItem>
                                    {!company.is_active &&
                                      <DropdownItem onClick={() => handleDelete(company.id)} className='remove-item-btn'> <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </DropdownItem>
                                    }
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p style={{ textAlign: 'center', fontSize: '16px' ,paddingTop: '30px'}}>No data found</p>
                    )):(
                    <p style={{ textAlign: 'center', fontSize: '16px' ,paddingTop: '30px'}}>
                      <CircularProgress color="inherit" size={100} />
                    </p>
                  )}
                </div>
              </div>
            </CardBody>
            <div className="d-flex justify-content-between my-2 mx-2">
              {companies.length > 0 && (
                <PaginationComponent
                  listCall={getAllCompaniesApi}
                  params={sortBy}
                  paginationMeta={meta}
                  emitItem={(newValue) => setCompanies(newValue)}
                  emitSelectedLimit={(limit) => setLimit(limit)}
                />
              )}
            </div>
          </Card>
        </Container>
      </div>

      {/* Add Subscription sidebar */}
      <Offcanvas isOpen={addSubscription} toggle={() => {tog_add_subscription()}} direction="end" className="offcanvas-end border-0 bottom_save_offcanvas">
        <div className="d-flex justify-content-between align-items-center p-4 pb-0">
          <h4 className="bold_heading mb-0">Add Subscription</h4>
          <Button className="d-inline-flex transparent_btn only_icon" onClick={() => {tog_add_subscription()}}>
            <i className="ri-close-circle-line"></i>
          </Button>
        </div>
        <OffcanvasBody className="p-0">
          <SimpleBar className="simplebar_holder">
            <div className="p-4">
              <form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col xl={12} className="mb-3">
                    <TextField
                      fullWidth
                      label={<span>Company Name<span style={{ color: 'red' }}> *</span></span>}
                      type='text'
                      value={validation.values.company_name || ""}
                      onChange={(event) => validation.setFieldValue("company_name", event.target.value)}
                      onBlur={validation.handleBlur}
                      variant="outlined"
                    />
                    {validation.touched.company_name && validation.errors.company_name && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{validation.errors.company_name}</p>
                    )}
                  </Col>
                  <Col xl={12} className='mb-3'>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                      Subscription Plan<span style={{ color: 'red' }}> *</span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Subscription Plan"
                        value={validation.values.subscription_plan}
                        onChange={(event) => {
                          validation.setFieldValue("subscription_plan", event.target.value)
                        }
                        }
                        MenuProps={{PaperProps: {style: {maxHeight: '200px',},},}}
                        onBlur={validation.handleBlur}
                      >
                        {filteredPlans?.map((plan) => (
                          <MenuItem onClick={()=>setSelectedPlanName(plan.name)} key={plan.id} value={plan.id}>
                            {plan.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {validation.touched.subscription_plan && validation.errors.subscription_plan && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{validation.errors.subscription_plan}</p>
                    )}
                  </Col>
                  {plans.find((plan) => plan.id === validation.values.subscription_plan)?.name === planName.ENTERPRISE && (<Col xl={12} className='mb-3'>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                      Enterprise Type<span style={{ color: 'red' }}> *</span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Enterprise Type"
                        value={validation.values.enterprise_type}
                        onChange={(event) => {
                          validation.setFieldValue("enterprise_type", event.target.value)
                        }
                        }
                        MenuProps={{PaperProps: {style: {maxHeight: '200px',},},}}
                        onBlur={validation.handleBlur}
                      >
                        {Object.values(EnterpriseCompanyType)?.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {validation.errors.enterprise_type && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{validation.errors.enterprise_type}</p>
                    )}
                  </Col>)}
                  <Col xl={6} className="mb-3">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                      Term<span style={{ color: 'red' }}> *</span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Term"
                        value={validation.values.term}
                        onChange={(event) => {
                          validation.setFieldValue("term", event.target.value)
                        }}
                        MenuProps={{PaperProps: {style: {maxHeight: '200px',},},}}
                        onBlur={validation.handleBlur}
                      >
                        <MenuItem value={"Monthly"}>Monthly</MenuItem>
                        <MenuItem value={"Yearly"}>Yearly</MenuItem>
                      </Select>
                    </FormControl>
                    {validation.touched.term && validation.errors.term && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{validation.errors.term}</p>
                    )}
                  </Col>
                  <Col xl={6} className="mb-3">
                    <TextField
                      fullWidth
                      label={<span>User Limit<span style={{ color: 'red' }}> *</span></span>}
                      type='number'
                      value={validation.values.user || ""}
                      onChange={(event) => validation.setFieldValue("user", event.target.value)}
                      onBlur={validation.handleBlur}
                      variant="outlined"
                    />
                    {validation.touched.user && validation.errors.user && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{validation.errors.user}</p>
                    )}
                  </Col>
                  <Col xl={6} className="mb-3">
                    <TextField
                      fullWidth
                      label={<span>Monthly Price Per User ($)<span style={{ color: 'red' }}> *</span></span>}
                      type='number'
                      value={validation.values.monthly_cost || ""}
                      onChange={(event) => validation.setFieldValue("monthly_cost", event.target.value)}
                      onBlur={validation.handleBlur}
                      variant="outlined"
                    />
                    {validation.touched.monthly_cost && validation.errors.monthly_cost && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{validation.errors.monthly_cost}</p>
                    )}
                  </Col>
                  <Col xl={6} className="mb-3">
                    <TextField
                      fullWidth
                      label={<span>Yearly price per user ($)<span style={{ color: 'red' }}> *</span></span>}
                      type='number'
                      value={validation.values.yearly_cost || ""}
                      onChange={(event) => validation.setFieldValue("yearly_cost", event.target.value)}
                      onBlur={validation.handleBlur}
                      variant="outlined"
                    />
                    {validation.touched.yearly_cost && validation.errors.yearly_cost && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{validation.errors.yearly_cost}</p>
                    )}
                  </Col>
                  {
                    selectedPlanName === planName.TRIAL && <Col xl={6} className="mb-3">
                      <TextField
                        fullWidth
                        label={<span>Trial Period<span style={{ color: 'red' }}> *</span></span>}
                        type='number'
                        value={validation.values.trial_period || ""}
                        onChange={(event) => validation.setFieldValue("trial_period", event.target.value)}
                        onBlur={validation.handleBlur}
                        variant="outlined"
                      />
                      {validation.touched.trial_period && validation.errors.trial_period && (
                        <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{validation.errors.trial_period}</p>
                      )}
                    </Col>
                  }
                  <Col xl={6} className="mb-3">
                    <TextField
                      fullWidth
                      label={<span>Grace Period</span>}
                      type='number'
                      value={validation.values.grace_period}
                      onChange={(event) => validation.setFieldValue("grace_period", event.target.value)}
                      onBlur={validation.handleBlur}
                      variant="outlined"
                    />
                    {validation.touched.grace_period && validation.errors.grace_period && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{validation.errors.grace_period}</p>
                    )}
                  </Col>
                  <Col lg={12} md={12} className='mb-3'>
                    <div className='flatpickr_with_icon'>
                      <Flatpickr
                        placeholder=""
                        className="form-control"
                        id="datepicker-publish-input"
                        options={{
                          altInput: true,
                          clickOpens: false,
                          altFormat: dateFormatFlatPicker,
                          dateFormat: "Y-m-d",
                          minDate: "today",
                        }}
                        ref={addInputDateRef}
                        value={validation.values.start_date}
                        onChange={(e) => validation.setFieldValue("start_date",e[0])}
                      />
                      <button className='icon_btn' type='button'
                        onClick={() => {
                          if (addInputDateRef.current && addInputDateRef.current.flatpickr) {
                            addInputDateRef.current.flatpickr.open()
                          }
                        }}
                      >
                        <i className='ri-calendar-line'></i>
                      </button>
                      {!validation.values.start_date && <span style={{color: 'gray'}} className='placeholder_span'>Subscription Start Date<span style={{color: 'red'}}> *</span></span>}
                    </div>
                    {validation.touched.start_date && validation.errors.start_date && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                        {validation.errors.start_date}
                      </p>
                    )}
                  </Col>
                  {validation.values.start_date && validation.values.next_renewal_date && (<Col lg={12} md={12} className="mb-3">
                    <InputLabel id="demo-simple-select-label">
                      Next Renewal Date
                    </InputLabel>
                    <div className="flatpickr_with_icon">
                      <Flatpickr
                        type="text"
                        className="form-control"
                        placeholder="Next Renewal Date"
                        name="next_renewal_date"
                        options={{
                          clickOpens: false,
                          dateFormat: dateFormatFlatPicker,
                        }}
                        value={validation.values.next_renewal_date ? convertToUTCISO(validation.values.next_renewal_date) : ''}
                        readOnly
                      />
                      <button className='icon_btn' type='button'>
                        <i className='ri-calendar-line'></i>
                      </button>
                      {validation.touched.next_renewal_date && validation.errors.next_renewal_date && (
                        <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                          {validation.errors.next_renewal_date}
                        </p>
                      )}
                    </div>
                  </Col>)}
                  {
                    selectedPlanName === planName.ENTERPRISE && <Col xl={12} className="mb-3">
                      <TextField
                        fullWidth
                        label={<span>Subdomain Name</span>}
                        type='text'
                        value={validation.values.subdomain_name || ""}
                        onChange={(event) => validation.setFieldValue("subdomain_name", event.target.value)}
                        onBlur={validation.handleBlur}
                        variant="outlined"
                      />
                      {validation.touched.subdomain_name && validation.errors.subdomain_name && (
                        <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{validation.errors.subdomain_name}</p>
                      )}
                    </Col>
                  }
                </Row>

                <h4 className="bold_heading mb-3">Contact Information</h4>
                <Row>
                  <Col xl={12} className="mb-3">
                    <TextField
                      fullWidth
                      label={<span>Full Name<span style={{ color: 'red' }}> *</span></span>}
                      variant="outlined"
                      type='text'
                      value={validation.values.contact_name || ""}
                      onChange={(event) => validation.setFieldValue("contact_name", event.target.value)}
                      onBlur={validation.handleBlur}
                    />
                    {validation.touched.contact_name && validation.errors.contact_name && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{validation.errors.contact_name}</p>
                    )}
                  </Col>
                  <Col xl={12} className="mb-3">
                    <TextField
                      fullWidth
                      label={<span>Email Address<span style={{ color: 'red' }}> *</span></span>}
                      type='email'
                      value={validation.values.contact_email || ""}
                      onChange={(event) => validation.setFieldValue("contact_email", event.target.value)}
                      onBlur={validation.handleBlur}
                      variant="outlined"
                    />
                    {validation.touched.contact_email && validation.errors.contact_email && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{validation.errors.contact_email}</p>
                    )}
                  </Col>
                  <Col xl={12} className="mb-3">
                    <TextField
                      fullWidth
                      label={<span>Phone Number<span style={{ color: 'red' }}> *</span></span>}
                      type='phone'
                      value={validation.values.contact_phone || ""}
                      onChange={(event) => validation.setFieldValue("contact_phone", event.target.value)}
                      onBlur={validation.handleBlur}
                      variant="outlined"
                    />
                    {validation.touched.contact_phone && validation.errors.contact_phone && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{validation.errors.contact_phone}</p>
                    )}
                  </Col>
                  <Col xl={12} className="mb-3">
                    <TextField
                      fullWidth
                      label={<span>Address<span style={{ color: 'red' }}> *</span></span>}
                      type='text'
                      value={validation.values.contact_address || ""}
                      onChange={(event) => validation.setFieldValue("contact_address", event.target.value)}
                      onBlur={validation.handleBlur}
                      variant="outlined"
                    />
                    {validation.touched.contact_address && validation.errors.contact_address && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{validation.errors.contact_address}</p>
                    )}
                  </Col>
                </Row>
              </form>
            </div>
          </SimpleBar>
          <div className="save_btm_holder text-center">
            <button
              type="button"
              onClick={() => {validation.handleSubmit()}}
              className="btn btn-primary d-inline-flex main_primary_btn"
            >
              Save
            </button>
          </div>
        </OffcanvasBody>
      </Offcanvas>

      {/* Edit Subscription sidebar */}
      <Offcanvas isOpen={editSubscriptionModal} toggle={() => {tog_edit_subscription_modal()}} direction="end" className="offcanvas-end border-0 bottom_save_offcanvas">
        <div className="d-flex justify-content-between align-items-center p-4 pb-0">
          <h4 className="bold_heading mb-0">Edit Subscription</h4>
          <Button className="d-inline-flex transparent_btn only_icon" onClick={() => {tog_edit_subscription_modal()}}>
            <i className="ri-close-circle-line"></i>
          </Button>
        </div>
        <OffcanvasBody className="p-0">
          <SimpleBar className="simplebar_holder">
            <div className="p-4">
              <form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col xl={12} className="mb-3">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                      Company Status<span style={{ color: 'red' }}> *</span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Company Status"
                        value={editValidation.values.is_active}
                        onChange={(event) => {
                          editValidation.setFieldValue("is_active", event.target.value)
                        }}
                        MenuProps={{PaperProps: {style: {maxHeight: '200px',},},}}
                        onBlur={editValidation.handleBlur}
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xl={12} className="mb-3">
                    <TextField
                      fullWidth
                      label={<span>Company Name<span style={{ color: 'red' }}> *</span></span>}
                      type='text'
                      value={editValidation.values.company_name}
                      onChange={(event) => editValidation.setFieldValue("company_name", event.target.value)}
                      onBlur={editValidation.handleBlur}
                      variant="outlined"
                    />
                    {editValidation.touched.company_name && editValidation.errors.company_name && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{editValidation.errors.company_name}</p>
                    )}
                  </Col>
                  <Col xl={12} className='mb-3'>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                      Subscription Plan<span style={{ color: 'red' }}> *</span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Subscription Plan"
                        readOnly={true}
                        disabled={true}
                        value={editValidation.values.subscription_plan}
                        onChange={(event) => {
                          editValidation.setFieldValue("subscription_plan", event.target.value)
                        }}
                        MenuProps={{PaperProps: {style: {maxHeight: '200px',},},}}
                        onBlur={editValidation.handleBlur}
                      >
                        {plans?.map((plan) => (
                          <MenuItem onClick={()=>setEditSelectedPlanName(plan.name)} key={plan.id} value={plan.id}>
                            {plan.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {editValidation.touched.subscription_plan && editValidation.errors.subscription_plan && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{editValidation.errors.subscription_plan}</p>
                    )}
                  </Col>
                  {plans?.find((plan) => plan.id === editValidation.values.subscription_plan)?.name === planName.ENTERPRISE && (<Col xl={12} className='mb-3'>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                      Enterprise Type<span style={{ color: 'red' }}> *</span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Enterprise Type"
                        value={editValidation.values.enterprise_type}
                        disabled={true}
                        onChange={(event) => editValidation.setFieldValue("enterprise_type", event.target.value)}
                        MenuProps={{PaperProps: {style: {maxHeight: '200px',},},}}
                        onBlur={editValidation.handleBlur}
                      >
                        {Object.values(EnterpriseCompanyType)?.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {editValidation.errors.enterprise_type && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{editValidation.errors.enterprise_type}</p>
                    )}
                  </Col>)}
                  {
                    editSelectedPlanName === planName.TRIAL && <Col xl={12} className="mb-3">
                      <TextField
                        fullWidth
                        label={<span>Trial period<span style={{ color: 'red' }}> *</span></span>}
                        type='number'
                        value={editValidation.values.trial_period}
                        onChange={(event) => editValidation.setFieldValue("trial_period", event.target.value)}
                        onBlur={editValidation.handleBlur}
                        variant="outlined"
                      />
                      {editValidation.touched.trial_period && editValidation.errors.trial_period && (
                        <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{editValidation.errors.trial_period}</p>
                      )}
                    </Col>
                  }
                  <Col xl={6} className="mb-3">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                      Term<span style={{ color: 'red' }}> *</span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Term"
                        value={editValidation.values.term}
                        onChange={(event) => {
                          editValidation.setFieldValue("term", event.target.value)
                        }}
                        MenuProps={{PaperProps: {style: {maxHeight: '200px',},},}}
                        onBlur={editValidation.handleBlur}
                      >
                        <MenuItem value={"Monthly"}>Monthly</MenuItem>
                        <MenuItem value={"Yearly"}>Yearly</MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xl={6} className="mb-3">
                    <TextField
                      fullWidth
                      label={<span>User Limit<span style={{ color: 'red' }}> *</span></span>}
                      type='number'
                      value={editValidation.values.user}
                      onChange={(event) => editValidation.setFieldValue("user", event.target.value)}
                      onBlur={editValidation.handleBlur}
                      variant="outlined"
                    />
                    {editValidation.touched.user && editValidation.errors.user && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{editValidation.errors.user}</p>
                    )}
                  </Col>
                  <Col xl={6} className="mb-3">
                    <TextField
                      fullWidth
                      label={<span>Monthly Price Per User ($)<span style={{ color: 'red' }}> *</span></span>}
                      type='number'
                      value={editValidation.values.monthly_cost}
                      onChange={(event) => editValidation.setFieldValue("monthly_cost", event.target.value)}
                      onBlur={editValidation.handleBlur}
                      variant="outlined"
                    />
                    {editValidation.touched.monthly_cost && editValidation.errors.monthly_cost && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{editValidation.errors.monthly_cost}</p>
                    )}
                  </Col>
                  <Col xl={6} className="mb-3">
                    <TextField
                      fullWidth
                      label={<span>Yearly Price Per User ($)<span style={{ color: 'red' }}> *</span></span>}
                      type='number'
                      value={editValidation.values.yearly_cost}
                      onChange={(event) => editValidation.setFieldValue("yearly_cost", event.target.value)}
                      onBlur={editValidation.handleBlur}
                      variant="outlined"
                    />
                    {editValidation.touched.yearly_cost && editValidation.errors.yearly_cost && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{editValidation.errors.yearly_cost}</p>
                    )}
                  </Col>
                  <Col xl={6} className="mb-3">
                    <TextField
                      fullWidth
                      label={<span>Grace Period</span>}
                      type='number'
                      value={editValidation.values.grace_period || 0}
                      onChange={(event) => editValidation.setFieldValue("grace_period", event.target.value)}
                      onBlur={editValidation.handleBlur}
                      variant="outlined"
                    />
                    {editValidation.touched.grace_period && editValidation.errors.grace_period && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{editValidation.errors.grace_period}</p>
                    )}
                  </Col>
                  <Col lg={12} md={12} className='mb-3'>
                    {editValidation.values.start_date && (<InputLabel id="demo-simple-select-label">
                      Subscription Start Date<span style={{ color: 'red' }}> *</span>
                    </InputLabel>)}
                    <div className='flatpickr_with_icon'>
                      <Flatpickr
                        className="form-control"
                        id="datepicker-publish-input"
                        options={{
                          altInput: true,
                          clickOpens: false,
                          altFormat: dateFormatFlatPicker,
                          dateFormat: "Y-m-d"
                        }}
                        ref={editInputDateRef}
                        value={editValidation.values.start_date}
                        onChange={(e) => editValidation.setFieldValue("start_date",e[0])}
                      />
                      <button className='icon_btn' type='button'
                        onClick={() => {
                          if (editInputDateRef.current && editInputDateRef.current.flatpickr) {
                            editInputDateRef.current.flatpickr.open()
                          }
                        }}
                      >
                        <i className='ri-calendar-line'></i>
                      </button>
                      {!editValidation.values.start_date && <span style={{color: 'gray'}} className='placeholder_span'>Subscription Start Date<span style={{color: 'red'}}> *</span></span>}
                    </div>
                    {editValidation.touched.start_date && editValidation.errors.start_date && (
                      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{editValidation.errors.start_date}</p>
                    )}
                  </Col>
                  <Col lg={12} md={12} className='mb-3'>
                    {editValidation.values.next_renewal_date && (<InputLabel id="demo-simple-select-label">
                      Next Renewal Date
                    </InputLabel>)}
                    <div className='flatpickr_with_icon'>
                      <Flatpickr
                        className="form-control"
                        id="datepicker-publish-input"
                        placeholder="Next Renewal Date"
                        options={{
                          altInput: true,
                          altFormat: dateFormatFlatPicker,
                          dateFormat: "Y-m-d",
                          clickOpens: false,
                        }}
                        readOnly
                        name="next_renewal_date"
                        value={editValidation.values.next_renewal_date ? convertToUTCISO(editValidation.values.next_renewal_date) : ''}
                        onChange={(event) => editValidation.setFieldValue("next_renewal_date", event[0])}
                        MenuProps={{PaperProps: {style: {maxHeight: '200px',},},}}
                        onBlur={editValidation.handleBlur}
                      />
                      <button className='icon_btn' type='button'>
                        <i className='ri-calendar-line'></i>
                      </button>
                      {/* {editValidation.touched.next_renewal_date && editValidation.errors.next_renewal_date && (
                        <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{editValidation.errors.next_renewal_date}</p>
                      )} */}
                    </div>
                  </Col>
                  {
                    editSelectedPlanName === planName.ENTERPRISE && <Col xl={12} className="mb-3">
                      <TextField
                        fullWidth
                        label={<span>Subdomain name</span>}
                        type='text'
                        value={editValidation.values.subdomain_name || ''}
                        onChange={(event) => editValidation.setFieldValue("subdomain_name", event.target.value)}
                        onBlur={editValidation.handleBlur}
                        variant="outlined"
                      />
                      {editValidation.touched.subdomain_name && editValidation.errors.subdomain_name && (
                        <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{editValidation.errors.subdomain_name}</p>
                      )}
                    </Col>
                  }
                </Row>
              </form>
            </div>
          </SimpleBar>
          <div className="save_btm_holder text-center">
            <button
              type="button"
              onClick={() => editValidation.handleSubmit()}
              className="btn btn-primary d-inline-flex main_primary_btn"
            >
              Update
            </button>
          </div>
        </OffcanvasBody>
      </Offcanvas>

      {/* Details sidebar */}
      <Offcanvas isOpen={detail_list} toggle={() => { tog_detail_list() }} direction="end" className="offcanvas-end border-0 bottom_save_offcanvas">
        <div style={{ borderBottom: '1px solid #CAD2D7' }} className="d-flex justify-content-between align-items-center p-4">
          <h4 className="bold_heading m-0">View Subscription</h4>
          <Button className='d-inline-flex transparent_btn only_icon' onClick={() => setdetail_list(false)}>
            <i className="ri-close-circle-line"></i>
          </Button>
        </div>
        <OffcanvasBody className="p-0">
          <SimpleBar className="simplebar_holder details_user_sidebar">
            <Card className="var_sep_tab_holder">
              <div className="card-header align-items-center d-flex px-0">
                <Nav tabs className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0">
                  <NavItem>
                    <NavLink style={{ cursor: "pointer" ,marginRight: "10px",marginLeft: "10px" }} className={classnames({ active: cardHeaderTab === "1", })} onClick={() => { cardHeaderToggle("1") }} >
                      Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: cardHeaderTab === "2", })} onClick={() => { cardHeaderToggle("2") }} >
                      History Timeline
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <CardBody className="pt-1">
                <TabContent activeTab={cardHeaderTab} className="bottom_btn_sidebar text-muted">
                  <TabPane tabId="1">
                    <div className="dark_bg_holder details_user_sidebar_info align-items-start">
                      <div>
                        <label className="label_holder">Company Name</label>
                        <p className="info_title text-wrap">{viewCompany && viewCompany.company_name}</p>
                      </div>
                      <div>
                        <label className="label_holder">Subdomain Name</label>
                        <p className="info_title text-wrap">{viewCompany && viewCompany.subdomain_name ? `${viewCompany.subdomain_name}.vardian.io` : "N/A"}</p>
                      </div>
                    </div>

                    <div className="details_user_sidebar_info">
                      <Row className='w-100'>
                        <Col sm={6} xs={12}>
                          <label className="label_holder">Full Name</label>
                          <p className="info_title text-wrap">{viewCompany && (viewCompany.contacts[0]?.name ?? 'N/A')}</p>
                        </Col>
                        <Col sm={6} xs={12}>
                          <label className="label_holder">Email Address</label>
                          <p className="info_title text-wrap">{viewCompany && (viewCompany.contacts[0]?.email ?? 'N/A')}</p>
                        </Col>
                      </Row>
                    </div>

                    <div className="details_user_sidebar_info">
                      <Row className='w-100'>
                        <Col sm={6} xs={12}>
                          <label className="label_holder">Phone Number</label>
                          <p className="info_title text-wrap">{viewCompany && (viewCompany.contacts[0]?.phone ?? 'N/A')}</p>
                        </Col>
                        <Col sm={6} xs={12}>
                          <label className="label_holder">Address</label>
                          <p className="info_title text-wrap">{viewCompany && (viewCompany.contacts[0]?.address ?? 'N/A')}</p>
                        </Col>
                      </Row>
                    </div>

                    <div className="details_user_sidebar_info">
                      <Row className='w-100'>
                        <Col sm={6} xs={12}>
                          <label className="label_holder">Start Date</label>
                          <p className="info_title text-wrap">{viewCompany && convertDateTimeString(viewCompany.subscriptions[0]?.start_date, dateFormat)}</p>
                        </Col>
                        <Col sm={6} xs={12}>
                          <label className="label_holder">End Date</label>
                          <p className="info_title text-wrap">{viewCompany && convertDateTimeString(viewCompany.subscriptions[0]?.end_date, dateFormat)}</p>
                        </Col>
                      </Row>
                    </div>

                    <div className="details_user_sidebar_info">
                      <Row className='w-100'>
                        <Col sm={6} xs={12}>
                          <label className="label_holder">Plan</label>
                          <p className="info_title text-wrap">{viewCompany && viewCompany.subscriptions[0]?.plan?.name}</p>
                        </Col>
                        <Col sm={6} xs={12}>
                          <label className="label_holder">Term</label>
                          <p className="info_title text-wrap">{viewCompany && viewCompany.subscriptions[0]?.term}</p>
                        </Col>
                      </Row>
                    </div>

                    <div className="details_user_sidebar_info">
                      <Row className='w-100'>
                        <Col sm={6} xs={12}>
                          <label className="label_holder">Monthly cost</label>
                          <p className="info_title text-wrap">{viewCompany && viewCompany.subscriptions[0]?.monthly_cost}</p>
                        </Col>
                        <Col sm={6} xs={12}>
                          <label className="label_holder">Yearly cost</label>
                          <p className="info_title text-wrap">{viewCompany && viewCompany.subscriptions[0]?.yearly_cost}</p>
                        </Col>
                      </Row>
                    </div>

                    <div className="details_user_sidebar_info">
                      <Row className='w-100'>
                        <Col sm={6} xs={12}>
                          <label className="label_holder">Grace Period</label>
                          <p className="info_title text-wrap">{(viewCompany && viewCompany.subscriptions[0]?.grace_period) ?? 0}</p>
                        </Col>
                        <Col sm={6} xs={12}>
                          <label className="label_holder">Trial period</label>
                          <p className="info_title text-wrap">{(viewCompany && viewCompany.subscriptions[0]?.trial_period) ? viewCompany.subscriptions[0]?.trial_period : 0} Days</p>
                        </Col>
                      </Row>
                    </div>

                    <div className="details_user_sidebar_info">
                      <Row className='w-100'>
                        {viewCompany && viewCompany.subscriptions[0]?.plan?.name === planName.ENTERPRISE && (<Col sm={6} xs={12}>
                          <label className="label_holder">Enterprise Type</label>
                          <p className="info_title text-wrap">{viewCompany && viewCompany.subscriptions[0]?.enterprise_type}</p>
                        </Col>)}
                        <Col sm={6} xs={12}>
                          <label className="label_holder">User Limit</label>
                          <p className="info_title text-wrap">{viewCompany && viewCompany.subscriptions[0]?.user_limit}</p>
                        </Col>
                      </Row>
                    </div>
                    <div className="details_user_sidebar_info d-flex justify-content-center">
                      <Button color="danger" className='d-inline-flex m-1 danger_red_btn' outline disabled={viewCompany?.is_active === false || viewCompany?.subscriptions[0]?.plan?.name !== "Professional" || viewCompany?.subscriptions[0]?.user_limit === 0} onClick={() => handleCancellation(viewCompany?.id)}>
                        <span>Cancel Subscription</span>
                      </Button>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    {activity && <Activity activity={activity} meta = {activityMeta} loadMore={(page) => subscriptionActivity(viewSubscriptionId,page)}/>}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </SimpleBar>
        </OffcanvasBody>
      </Offcanvas>

      {/* filter sidebar */}
      <FilterSidebar
        openFilterSidebar={openFilterSidebar}
        toggleFilterSidebar={toggleFilterSidebar}
        value={{keys: sortBy.filterFields, values: sortBy.filterInputs}}
        plans = {plans}
        emitFilter={(newValue) => {
          setSortBy({...sortBy, ...{filterFields: newValue.keys, filterInputs: newValue.values}, page: 1})
        }}
      />

      <GenerateLicenseKey id={selectedCompanyId} show={openLicenseKeyModal} onCloseClick={handleOpenGenerateLicenseKey}/>
    </React.Fragment>
  )
}

export default Subscriptions